import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import Header from "../components/Header";

const Login = ({ setAuthToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);

    try {
      const response = await axios.post("/api/v2/token", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const token = response.data.access_token;
      setAuthToken(token);
      sessionStorage.setItem("authToken", token);
      navigate("/admin");
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed");
    }
  };

  return (
    <Page>
      <Header header="Login" />
      <div className="bg-white px-10 py-10 rounded-3xl border-2 border-gray-300">
        <form onSubmit={handleLogin} className="space-y-4">
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Username:
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="p-2 border border-gray-300 rounded-xl text-black "
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Password:
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="p-2 border border-gray-300 rounded-xl text-black  "
            />
          </div>
          <div className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out text-lg font-bold">
            <button
              type="submit"
              className="w-full py-2 px-4 rounded-3xl text-black  border-2 border-black hover:bg-celine-gold"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </Page>
  );
};

export default Login;
