import React from "react";

import { BsChevronCompactLeft } from "react-icons/bs";

const PrevArrow = ({ className, onClick }) => (
    <div
        className={`prev-arrow ${className}`}
        onClick={onClick}
    >
        <BsChevronCompactLeft className="w-6 h-6 text-celine-gold " />
    </div>
);

export default PrevArrow;