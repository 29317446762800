import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";
import GalleryGrid from "../components/GalleryGrid";

const Gallery = () => {
  DocumentTitle("Gallery");

  return (
    <Page>
      <Header header="GALLERY" />
      <GalleryGrid />
    </Page>
  );
};

export default Gallery;
