import React from "react";

const Header = ({ header }) => {
  return (
    <div className="text-center ">
      <div className="header-component roboto-thin ">
        {header}
      </div>
    </div>
  );
};

export default Header;
