import React from "react";
import { BsChevronCompactRight } from "react-icons/bs";

const NextArrow = ({ className, onClick }) => (
    <div
        className= {`next-arrow ${className}`}
        onClick={onClick}
    >
        <BsChevronCompactRight className="w-6 h-6 text-celine-gold" />
    </div>
);

export default NextArrow;