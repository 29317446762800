import { Description } from '@headlessui/react';
import React from 'react';

const TeamMember = ({ imgSrc, name, role, description }) => {
  return (
    <li> 
      <div className="flex items-center gap-x-6 gap-y-2 pl-5">
        <img 
          className="h-40 w-40 rounded-lg object-cover shadow-xl" 
          src={imgSrc}
          alt={name}
        />
        <div>
          <h3 className="text-main-background font-normal">
            {name}
          </h3>
          <p className='text-celine-gold'>
            {role}
          </p>
          <p>
            {description}
          </p>
          </div>
      </div>
    </li>
  );
};

export default TeamMember;