import React from "react";
import Gig from "./Gig";
import NextArrow from "../arrows/NextArrow";
import PrevArrow from "../arrows/PrevArrow";

import Slider from "react-slick";





const Gigs = ({ gigs }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <div className="w-full h-full mb-10">
            {gigs.length > 3 ? (
                <div className="w-full h-full">
                    <Slider {...settings}>
                        {gigs.map((gig, index) => (
                            // Adjusted padding/margin for consistency
                            <div className="w-3/4 mx-auto h-full px-4 py-2">
                                <Gig key={index} {...gig} />
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : (
                <div className="flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
                        {gigs.map((gig, index) => (
                            <Gig key={index} {...gig} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
export default Gigs;