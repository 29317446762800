import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FaSquareXTwitter,
  FaInstagram,
  FaSquareFacebook,
  FaEnvelopeOpen,
} from "react-icons/fa6";
import { fetchLinks, getLinks } from "../constants/Links";

const links = [
  { path: "/", name: "Home" },
  { path: "/about", name: "About" },
  { path: "/gallery", name: "Gallery" },
  { path: "/admin", name: "Admin" },
];

const Footer = () => {
  const [socialLinks, setSocialLinks] = React.useState(getLinks());
  const footerRef = useRef(null);

  useEffect(() => {
    const loadLinks = async () => {
      const fetchedLinks = await fetchLinks();
      setSocialLinks(fetchedLinks);
    };

    loadLinks();
  }, []);

  // Expose socialLinks to the ref
  useEffect(() => {
    if (footerRef.current) {
      footerRef.current.socialLinks = socialLinks;
    }
  }, [socialLinks]);

  return (
    <section ref={footerRef}>
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-center -mx-5 -my-2">
          {links.map((link, index) => (
            <div key={index} className="px-5 py-2">
              <Link to={link.path} className="text-base leading-6">
                {link.name}
              </Link>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-8 space-x-6">
          <a href={socialLinks.Facebook}>
            <span className="sr-only">Facebook</span>
            <FaSquareFacebook
              className="social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href={socialLinks.Instagram}>
            <FaInstagram className="social-media-icon" aria-hidden="true" />
          </a>
          <a href={socialLinks.Twitter}>
            <span className="sr-only">Twitter</span>
            <FaSquareXTwitter
              className="social-media-icon"
              aria-hidden="true"
            />
          </a>
          <a href={socialLinks.Email}>
            <span className="sr-only">Email</span>
            <FaEnvelopeOpen className="social-media-icon" aria-hidden="true" />
          </a>
        </div>
        <p className="mt-8 text-base leading-6 text-center text-gray-400">
          © {new Date().getFullYear()} The Celine Experience.
        </p>
        <p className="mt-8 text-base leading-6 text-center text-gray-400">
          Made with <span role="img" aria-label="love">❤️</span> by <a href="https://mealteam.dev/" target="_blank" rel="noopener noreferrer" className="font-bold">Meal Team</a>.
        </p>
      </div>
    </section>
  );
};

export default Footer;
