import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";
import TeamMember from "../components/TeamMember";

const teamMembers = [
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    description:
      "Leslie Alexander is a co-founder and the CEO of our company. He has over 20 years of experience in the industry.",
    imageUrl: "/team/bor.jpg",
  },
  {
    name: "Robert Brown",
    role: "Data Analyst",
    imageUrl: "/team/man1.jpg",
  },
  {
    name: "David Thomas",
    role: "Scrum Master",
    imageUrl: "/team/man2.jpg",
  },
  {
    name: "Byron Bernstien",
    role: "Software Engineer",
    imageUrl: "/team/reck.jpg",
  },
  // Add more team members as needed
];

const About = () => {
  const title = "About The Show";

  DocumentTitle(title);

  return (
    <Page>
      <Header header={title} />

      <div className="grid grid-cols-2 gap-4 mb-20 mx-auto max-w-7xl">
        <div className="pr-8">
          <p>
            The ultimate Celine Dion tribute concert featuring an incredible
            full live band and starring the highly acclaimed vocalist January
            Butler. With dazzling costumes, jaw dropping vocals and a
            sensational ‘live concert’ feel, this show will take you on a two
            hour electrifying journey through Celine’s life and career as we
            travel through four decades of hits. Packed with Worldwide platinum
            selling hits including ‘It’s All Coming Back To Me Now’, ‘I’m
            Alive’, ‘Think Twice’, ‘My Heart will Go On’, ‘That’s The Way It
            Is’, ‘The Power Of Love’, ‘River Deep, Mountain High’ and many more
            hit songs and arrangements performed by January who has taken her
            years of studying the movements, mannerisms and intricate detail of
            Queen Celine and packaged everything up into a show which is only
            second to the real star herself.
          </p>
        </div>
        <div className="pl-8">
          <p>
            This show has it all and no one else comes close to recreating the
            sound, look and feel of this incredibly loved and admired global
            megastar with as much passion and brilliance. With phenominal
            powerhouse vocals and incredible live band accompaniment led by the
            legendary musical director Andy Mudd, this is a show not to be
            missed. So don’t ‘Think Twice’, book your tickets today and don’t
            miss out on the ‘Power of Love’.
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg min-w-screen py-12 sm:py-25">
        <h2 className="text-main-background roboto font-normal pl-8 mb-4">
          Meet the Team
        </h2>
        <div className="grid gap-x-10 gap-y-20 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 pl-4 ">
          {teamMembers.map((member) => (
            <TeamMember
              key={member.name}
              imgSrc={member.imageUrl}
              name={member.name}
              role={member.role}
              // description={member.description}
            />
          ))}
        </div>
      </div>

      {/* <div class="py-12 sm:py-25 flex flex-col sm:flex-row">
        <div class="max-w-2xl sm:pr-8">
          <h2>
            Venue Location
          </h2>
          <p>
            We are located at 123 Main Street, Anytown, Anystate, 12345.
          </p>
        </div>

       

        <div class="w-3/4 h-44 rounded-lg overflow-hidden">
          <iframe
            className="w-full h-full border-2 border-gray-200 rounded-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d502.1435124163932!2d-0.543548229392735!3d53.22433459419057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48785b2ee52fe7f1%3A0x19139ab0244df6cc!2sThe%20Ritz%20-%20JD%20Wetherspoon!5e0!3m2!1sen!2suk!4v1717887304486!5m2!1sen!2suk"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div> 
      */}
    </Page>
  );
};

export default About;
