import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";

import { FaWhatsapp, FaEnvelope } from "react-icons/fa6";


const Contact = () => {
  DocumentTitle("Contact Us");

  const phoneNumber = "07562882320";

  const email = "info@thecelineexperence.net"

  const formatPhoneNumber = (number) => {
    return number.replace(/\D/g, "");
  };

  return (
    <Page>
      <Header header="Contact Us" />
      <div className="flex flex-col sm:flex-row -mx-4 justify-center items-center space-x-20 mt-8">
        <div className="flex items-center space-x-4">
          <a href={`https://wa.me/${formatPhoneNumber(phoneNumber)}`} className="flex items-center space-x-2">
            <FaWhatsapp size={60} />
            <p className="my-auto">{phoneNumber}</p>
          </a>
        </div>

        <div className="flex items-center space-x-4 ">
          <a href={`mailto:${email}`}className="flex items-center space-x-2">
            <FaEnvelope size={50} />
            <p className="my-auto">{email}</p>
          </a>
        </div>
      </div>

      <div className="flex justify-center mt-8 flex-col items-center">
        <h3>Refund Policy</h3>

        <p>At The Celine Experience , we are committed to providing our customers with an exceptional experience. If you are not completely satisfied with your ticket purchase, we are here to help. This Refund Policy outlines the conditions under which refunds, returns, and exchanges will be granted for tickets.</p>

        <p>To be eligible for a return, your ticket must be unused and in the same condition that you received it. You have [30/60/90] calendar days from the date of purchase to initiate a return. A receipt or proof of purchase is required to process your return.</p>
      


      </div>
    </Page>
  );
};

export default Contact;
