import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdMenuOpen, MdClose } from "react-icons/md";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative z-50">
      {/* Background Div */}
      <div
        className={`fixed top-0 left-0 w-full h-24 transition-colors duration-200 ${
          isScrolled
            ? "bg-main-background shadow-md"
            : "bg-transparent shadow-none"
        }`}
      ></div>

      {/* Content Div */}
      <div className="fixed top-0 left-0 right-0 flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src="/celinelogo192.png"
              alt="Celine Logo"
              className="w-32 h-32"
            />
          </Link>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex text-lg">
          <li className="p-4">
            <Link to="/about">About</Link>
          </li>
          <li className="p-4">
            <Link to="/contact">Contact</Link>
          </li>
          <li className="p-4">
            <Link to="/gallery">Gallery</Link>
          </li>
        </ul>

        {/* Mobile Menu Toggle */}
        <div
          onClick={handleNav}
          className="block md:hidden cursor-pointer"
          style={{ zIndex: 150 }}
        >
          {nav ? (
            <MdClose
              size={30}
              className="navbar-icon bg-celine-gold/40 rounded-full cursor-pointer"
            />
          ) : (
            <MdMenuOpen
              size={30}
              className="navbar-icon bg-celine-gold/40 rounded-full cursor-pointer"
            />
          )}
        </div>

        {/* Mobile Menu */}
        <div
          className={`fixed left-0 top-0 w-full h-[25%] border-b border-gray-900 bg-main-background text-celine-gold transform transition-transform duration-300 ease-in-out ${
            nav ? "translate-y-0" : "-translate-y-full"
          }`}
          style={{ zIndex: 100 }}
        >
          <ul className="uppercase p-4">
            <li className="p-4 font-bold">
              <Link to="/about" onClick={handleNav}>
                About
              </Link>
            </li>
            <hr className="nav-break" />
            <li className="p-4 font-bold">
              <Link to="/gallery" onClick={handleNav}>
                Gallery
              </Link>
            </li>
            <hr className="nav-break" />
            <li className="p-4 font-bold">
              <Link to="/contact" onClick={handleNav}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

