import { useState, useEffect } from "react";
import axios from "axios";
import Page from "../components/Page";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

const Admin = ({ authToken }) => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    date: "",
    start_time: "",
    location: "",
    description: "",
    ticket_link: "",
  });
  const [links, setLinks] = useState({
    Facebook: "",
    Instagram: "",
    Twitter: "",
    Email: "",
  });
  const [selectedLink, setSelectedLink] = useState("Facebook");
  const [newLinkUrl, setNewLinkUrl] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
      return;
    }
    fetchEvents(authToken);
    fetchLinks(authToken);
  }, [authToken, navigate]);

  const fetchEvents = async (token) => {
    try {
      const response = await axios.get("/api/v2/events", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchLinks = async (token) => {
    try {
      const response = await axios.get("/api/v2/links", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLinks(response.data);
    } catch (error) {
      console.error("Error fetching links:", error);
    }
  };

  const handleEventInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({
      ...newEvent,
      [name]: value,
    });
  };

  const handleLinkSelectChange = (e) => {
    const { value } = e.target;
    setSelectedLink(value);
    setNewLinkUrl(links[value] || "");
  };

  const handleLinkInputChange = (e) => {
    setNewLinkUrl(e.target.value);
  };

  const validateURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    setError("");

    // Validate date and time
    const now = new Date();
    const eventDate = new Date(`${newEvent.date}T${newEvent.start_time}`);
    if (eventDate <= now) {
      setError("Event date and time must be in the future.");
      return;
    }

    // Validate URL
    if (!validateURL(newEvent.ticket_link)) {
      setError("Invalid URL.");
      return;
    }

    try {
      await axios.post("/api/v2/events", newEvent, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      fetchEvents(authToken);
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const handleUpdateLink = async (e) => {
    e.preventDefault();
    setError("");

    // Validate URL
    if (!validateURL(newLinkUrl)) {
      setError("Invalid URL.");
      return;
    }

    try {
      await axios.put(
        `/api/v2/links/${selectedLink}`,
        { link: newLinkUrl }, // Ensure the payload structure matches the schema
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        },
      );
      fetchLinks(authToken);
    } catch (error) {
      console.error("Error updating link:", error);
    }
  };

  const handleDeleteEvent = async (id) => {
    try {
      await axios.delete(`/api/v2/events/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      fetchEvents(authToken);
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  return (
    <Page>
      <Header header="Events" />
      <div className="bg-white px-10 py-10 rounded-3xl border-2 border-gray-300">
        <h2 className="text-2xl font-bold mb-4 text-black">Create Event</h2>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleCreateEvent} className="space-y-4 mb-8">
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Date:
            </label>
            <input
              type="date"
              name="date"
              value={newEvent.date}
              onChange={handleEventInputChange}
              className="p-2 border border-gray-300 rounded-xl text-black "
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Start Time:
            </label>
            <input
              type="time"
              name="start_time"
              value={newEvent.start_time}
              onChange={handleEventInputChange}
              className="p-2 border border-gray-300 rounded-xl text-black "
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Location:
            </label>
            <input
              type="text"
              name="location"
              value={newEvent.location}
              onChange={handleEventInputChange}
              className="p-2 border border-gray-300 rounded-xl text-black "
              placeholder="Location"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Description:
            </label>
            <textarea
              name="description"
              value={newEvent.description}
              onChange={handleEventInputChange}
              className="p-2 border border-gray-300 rounded-xl text-black "
              placeholder="Description"
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Ticket Link:
            </label>
            <input
              type="text"
              name="ticket_link"
              value={newEvent.ticket_link}
              onChange={handleEventInputChange}
              className="p-2 border border-gray-300 rounded-xl text-black "
              placeholder="Ticket Link"
              required
            />
          </div>
          <div className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out text-lg font-bold">
            <button
              type="submit"
              className="w-full py-2 px-4 rounded-3xl text-black  border-2 border-black hover:bg-celine-gold"
            >
              Create Event
            </button>
          </div>
        </form>
      </div>

      {/* Update links */}
      <div className="mb-8"></div>
      <div className="bg-white px-10 py-10 rounded-3xl border-2 border-gray-300">
        <h2 className="text-2xl font-bold mb-4 text-black">Update Links</h2>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleUpdateLink} className="space-y-4 mb-8">
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              Select Link to Update:
            </label>
            <select
              name="selectedLink"
              value={selectedLink}
              onChange={handleLinkSelectChange}
              className="p-2 border border-gray-300  rounded-xl text-black "
            >
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="Twitter">Twitter</option>
              <option value="Email">Email</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="mb-2 tracking-wide text-black text-lg font-bold">
              New URL:
            </label>
            <input
              type="text"
              name="newLinkUrl"
              value={newLinkUrl}
              onChange={handleLinkInputChange}
              className="p-2 border border-gray-300 rounded-xl text-black "
              placeholder="New URL"
              required
            />
          </div>
          <div className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out text-lg font-bold">
            <button
              type="submit"
              className="w-full py-2 px-4 rounded-3xl text-black  border-2 border-black hover:bg-celine-gold"
            >
              Update Link
            </button>
          </div>
        </form>
      </div>

      {/* Existing events */}
      {/* Add space between the two divs */}
      <div className="mb-8"></div>
      <div className="bg-white px-10 py-10 rounded-3xl border-2 border-gray-300 gap-4">
        <h2 className="text-2xl font-bold mb-4 text-black">Existing Events</h2>
        <ul className="space-y-4">
          {events.map((event) => (
            <li
              key={event.id}
              className="p-4 bg-card-background border-2 border-celine-gold rounded-xl flex justify-between items-center"
            >
              <div>
                <h3 className="text-xl font-bold">{event.name}</h3>
                <p>
                  {event.date} {event.start_time}
                </p>
                <p>{event.location}</p>
                <p>{event.description}</p>
                <p>
                  <a
                    href={event.ticket_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline font-bold"
                  >
                    Ticket Link
                  </a>
                </p>
              </div>
              <div className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out text-lg font-bold">
                <button
                  onClick={() => handleDeleteEvent(event.id)}
                  className="ml-4 py-2 px-4 rounded text-white border-2 border-white hover:bg-celine-gold"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Page>
  );
};

export default Admin;
