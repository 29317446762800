import axios from "axios";

let links = null;

export const fetchLinks = async () => {
  if (links) return links; // Return cached links if already fetched

  try {
    const { data } = await axios.get("/api/v2/links");
    links = data.reduce((acc, link) => {
      acc[link.name] = link.link;
      return acc;
    }, {});
  } catch (error) {
    console.error("Failed to fetch links:", error);
    // Fallback to default links in case of error
    links = {
      Facebook: "https://www.facebook.com/",
      Instagram: "https://www.instagram.com/",
      Twitter: "https://twitter.com/",
      Email: "mailto:info@thecelineexperience.net",
    };
  }

  return links;
};

export const getLinks = () => {
  // Provide default links initially
  return (
    links || {
      Facebook: "https://www.facebook.com/",
      Instagram: "https://www.instagram.com/",
      Twitter: "https://twitter.com/",
      Email: "mailto:info@thecelineexperience.net",
    }
  );
};
