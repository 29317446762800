import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";

const NotFound = () => {
  DocumentTitle("404 - Page Not Found");

  return (
    <Page>
      <Header header="404 - Page Not Found" />
      <p>Oh dear, we cannot find that.</p>
    </Page>
  );
};

export default NotFound;
