import React, { useEffect, useState } from "react";
import axios from "axios";
import DocumentTitle from "../components/DocumentTitle";
import Page from "../components/Page";
import Gigs from "../components/gigs/Gigs";

import { GiTerror } from "react-icons/gi";
import { FaCalendarPlus } from "react-icons/fa6";
import { MdArrowForward } from "react-icons/md";

import BeatLoader from "react-spinners/BeatLoader";
import { Link } from "react-router-dom";

const Home = () => {
  DocumentTitle("Home");

  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch gigs data from the API
  useEffect(() => {
    const fetchGigs = async () => {
      try {
        const response = await axios.get("/api/v2/events");
        setGigs(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGigs();
  }, []);

  return (
    <>
      <div className="cover-image-container">
        <img
          src="/celine/covers/image1.jpg"
          alt="Cover"
          className="cover-image"
        />
        <div className="cover-text">
          
          {/* 
            <p className="text-3xl roboto-thin text-white shadow-lg">The English Tour</p>
          
          */}
          <h2 className="text-7xl text-white shadow-xl">The Celine Experience</h2>
          <p className="text-2xl text-white uppercase">
            The Ultimate Celebration to the 'Queen of Power Ballads'
          </p>
          <p className="text-2xl text-white uppercase"> 
            Celine Dion
          </p>
        </div>
      </div>
      <Page>
        <div className= "w-4/5 mx-auto pt-4">
          <div className="flex flex-col md:flex-row my-8 bg-white rounded">
            <div className="flex-1 px-5  py-6">
              <h2> Performing some of the greatest songs of all time </h2>
              <p className="text-black">There are many tributes to Celine Dion - none of whom perform in a show quite as unique, exciting and as popular as The Celine Experience</p>
              <p className="text-black">It's the show performing the music from an all time icon who has generated over 200 million album sales, won numerous awards, received glowing accolades, featured in movie soundtracks and who is famous around the whole World.</p>
              <p className="text-black">Hits such as Think Twice, My Heart Will Go On, The Power Of Love, I Drove All Night, I'm Alive & many many more</p>
              <Link to="/about" className="flex pt-4 items-center gap-2 text-celine-gold uppercase">
                More about the show <MdArrowForward />
              </Link>
            </div>
            <div className="flex-1 px-4 py-4 ">
              <img src="/celine/batch1/image3.jpg" alt="Descriptive Alt Text" className="max-w w-full h-auto border-4 border-celine-gold " />
            </div>  
          </div>
        </div>

        {/* <div className= "w-4/5 mx-auto pt-4">
          <div className="flex flex-col md:flex-row my-8 rounded">
            <div className="flex-1 px-4 py-4 ">
              <img src="/celine/batch1/image3.jpg" alt="Descriptive Alt Text" className="max-w w-full h-auto border-4 border-celine-gold " />
            </div>  
            <div className="flex-1 px-5  py-6">
              <h2> Le concert hommage ultime à Céline Dion avec un incroyable groupe live complet. </h2>
              <p className="">Plongez dans l’univers extraordinaire de Céline Dion avec « Celebrating to Celine », le concert hommage ultime qui honore l’une des plus grandes voix de notre époque. Laissez-vous transporter par une expérience musicale inoubliable, animée par un groupe live complet qui recrée à la perfection l’ambiance et l’énergie des spectacles de Céline. </p>
            </div>
          </div>
        </div>     */}

        <div className="w-4/5 mx-auto pt-4 pb-4">
          <div className="flex justify-center rounded">
            <iframe
              width="800"
              height="450"
              src="https://www.youtube.com/embed/u3UU4Kvoy4Y?si=T-UJYOoCHm7iJiVW"
              title="The Celine Experience Promo Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="w-4/5 mx-auto pt-4">
          {loading ? (
            <div className="flex flex-col justify-center items-center space-y-6">
              <BeatLoader color="#e5bc3c" loading={true} size={15} />
            </div>
          ) : error ? (
            <div className="flex flex-col justify-center items-center space-y-6">
              <GiTerror size={100} />
              <p className="text-xl text-error">{error}</p>
            </div>
          ) : gigs.length === 0 ? (
            <div className="flex flex-col justify-center items-center space-y-6">
              <p className="text-xl text-celine-gold"> 
                No gigs available... Check back later!
              </p>
              <Link to="/login">
                <FaCalendarPlus className="text-grey-light" size={50} />
              </Link>
            </div>
          ) : (
            <Gigs gigs={gigs} />
          )}
        </div>
      </Page>
    </>
  );
};

export default Home;
