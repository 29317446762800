import { useEffect, useRef } from "react";

export default function GalleryGrid() {
  const images = [
    { src: "/celine/batch1/image1.jpg", alt: "image 1" },
    { src: "/celine/batch1/image2.jpg", alt: "image 2" },
    { src: "/celine/batch1/image3.jpg", alt: "image 3" },
    { src: "/celine/batch1/image4.jpg", alt: "image 4" },
    { src: "/celine/batch1/image5.jpg", alt: "image 5" },
    { src: "/celine/batch1/image6.jpg", alt: "image 6" },
    { src: "/celine/batch1/image7.jpg", alt: "image 7" },
    { src: "/celine/batch1/image8.jpg", alt: "image 8" },
    { src: "/celine/batch1/image9.jpg", alt: "image 9" },
  ];

  const imageRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    });

    imageRefs.current.forEach((img) => {
      if (img) {
        observer.observe(img);
      }
    });

    return () => {
      imageRefs.current.forEach((img) => {
        if (img) {
          observer.unobserve(img);
        }
      });
    };
  }, []);

  const columns = 3;

  // Split images into columns for vertical distribution
  const columnImages = Array.from({ length: columns }, () => []);
  images.forEach((image, index) => {
    columnImages[index % columns].push(image);
  });

  return (
    <div className="flex justify-center">
      <div className="gallery-container max-w-screen-xl rounded-lg">
        {columnImages.map((column, colIndex) => (
          <div key={colIndex} className="gallery-column">
            {column.map((image, imgIndex) => (
              <div key={imgIndex} className="flex-shrink-0">
                <img
                  ref={(el) => (imageRefs.current[images.indexOf(image)] = el)}
                  className="h-auto max-w-full rounded-lg object-cover object-center fade-in"
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

