import { useState, useEffect } from "react";
import axios from "axios";
import DocumentTitle from "../components/DocumentTitle";
import Header from "../components/Header";
import Page from "../components/Page";

function TestPage() {
  const [testMessage, setTestMessage] = useState("Dead and buried.");

  // Retrieve test message from the API
  const fetchTestMessage = async () => {
    try {
      const response = await axios.get("/api/v2/health_check");
      setTestMessage(response.data.status);
    } catch (error) {
      console.error(error);
      setTestMessage("Dead and buried.");
    }
  };

  // Fetch message
  useEffect(() => {
    fetchTestMessage();
  }, []);

  DocumentTitle("Test Page");

  return (
    <Page>
      <Header header="Test Page" />
      <p>{testMessage}</p>
    </Page>
  );
}

export default TestPage;
